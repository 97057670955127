<template>
  <FormComponent
    :title="`Edit Miscellaneous Invoice - ${invoiceDetails.invoice_no}`"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formContent>
      <v-form v-model="isInvoiceFormValid">
        <v-row class="ma-2">
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Select Customer *"
              :items="allCustomerList"
              itemText="company_name"
              itemValue="id"
              :rules="[(val) => !!val || 'Customer is Required!']"
              hide-details="auto"
              v-model="invoiceDetails.customer"
              :error-messages="formErrors.customer"
              @input="delete formErrors.customer"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <DateField
              :inputProps="{
                dense: true,
                disabled: isReadOnly,
                label: 'Invoice Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Invoice Date is Required!'],
                'error-messages': formErrors.invoice_date,
              }"
              v-model="invoiceDetails.invoice_date"
              @input="delete formErrors.invoice_date"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="text"
              label="BOE"
              :clearable="true"
              v-model="invoiceDetails.boe"
              :error-messages="formErrors.boe"
              @input="delete formErrors.boe"
            />
          </v-col>

          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Select Branch *"
              :items="allBranchList"
              itemText="branch_title"
              itemValue="branch_name"
              :rules="[(val) => !!val || 'Branch is Required!']"
              hide-details="auto"
              v-model="invoiceDetails.branch"
              :error-messages="formErrors.branch"
              @input="delete formErrors.branch"
            ></MultiSelect>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Source Country *"
              :items="allCountryList"
              itemText="name"
              itemValue="name"
              :rules="[(val) => !!val || 'Source Country is Required!']"
              hide-details="auto"
              v-model="invoiceDetails.departure_country"
              :error-messages="formErrors.departure_country"
              @input="delete formErrors.departure_country"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Mid Country *"
              :items="allCountryList"
              itemText="name"
              itemValue="name"
              :rules="[(val) => !!val || 'Mid Country is Required!']"
              hide-details="auto"
              v-model="invoiceDetails.mid_country"
              :error-messages="formErrors.mid_country"
              @input="delete formErrors.mid_country"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Destination Country"
              :items="allCountryList"
              itemText="name"
              itemValue="name"
              :clearable="true"
              hide-details="auto"
              v-model="invoiceDetails.destination_country"
              :error-messages="formErrors.destination_country"
              @input="delete formErrors.destination_country"
            />
          </v-col>
          <v-col cols="12" lg="12" class="Form-Columns-Dense">
            <TextareaField
              hide-details="auto"
              type="text"
              label="Remarks"
              v-model="invoiceDetails.remarks"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row class="ma-0">
        <v-col cols="12" class="px-0" v-if="invoiceDetails">
          <div>
            <v-card class="ma-3">
              <v-card-title class="heading px-4 py-2">
                <span>Edit Charge</span>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="
                    invoiceDetails.customer_invoice_da_charges.push({
                      charge_type: 'Other',
                      quantity: 1,
                      charge_level: 'Customer Invoice',
                    })
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-3">
                <v-form v-model="chargesValid">
                  <v-row
                    v-for="(
                      charge, i
                    ) in invoiceDetails.customer_invoice_da_charges"
                    :key="i + keyUpdate"
                    class="ma-0 justify-space-between"
                  >
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" lg="2" class="Form-Columns-Dense">
                          <MultiSelect
                            label="Charge *"
                            :items="allChargeList"
                            itemText="charge_title"
                            itemValue="id"
                            :rules="[(v) => !!v || 'Charge is Required']"
                            :returnObject="true"
                            hide-details="auto"
                            v-model="charge.charge"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].charge
                                ? formErrors.customer_invoice_da_charges[i]
                                    .charge
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].charge
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].charge
                                : null;
                              selectedCharge($event, i);
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="2" class="Form-Columns-Dense">
                          <MultiSelect
                            outlined
                            hide-details="auto"
                            label="Tax Type *"
                            :items="taxTypeList"
                            itemText="display_name"
                            itemValue="value"
                            v-model="charge.tax_type"
                            :error-messages="formErrors.tax_type"
                          />
                        </v-col>
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <InputField
                            label="Description"
                            readonly
                            :clearable="false"
                            hide-details="auto"
                            v-model="charge.description"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i]
                                .description
                                ? formErrors.customer_invoice_da_charges[i]
                                    .description
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i]
                                .description
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].description
                                : null
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="1" class="Form-Columns-Dense">
                          <InputField
                            label="Revenue GL No."
                            readonly
                            :clearable="false"
                            hide-details="auto"
                            v-model="charge.gl_no"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].gl_no
                                ? formErrors.customer_invoice_da_charges[i]
                                    .gl_no
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].gl_no
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].gl_no
                                : null
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="1" class="Form-Columns-Dense">
                          <InputField
                            type="number"
                            :label="`Rate (${$globals.currency}) *`"
                            :disabled="!charge.charge"
                            :clearable="false"
                            :rules="[
                              (val) =>
                                $globals.fourDecimalPlacesRegExp.test(val) ||
                                'Rate must have 4 decimal places only',
                              (val) =>
                                val > 0 ||
                                'Rate can not be less than or equal to 0',
                            ]"
                            hide-details="auto"
                            v-model="charge.rate"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].rate
                                ? formErrors.customer_invoice_da_charges[i].rate
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].rate
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].rate
                                : null;
                              calculateAmount(i);
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="1" class="Form-Columns-Dense">
                          <InputField
                            type="number"
                            :label="`Quantity *`"
                            :disabled="!charge.charge"
                            :clearable="false"
                            :rules="[(val) => !!val || 'Quantity is Required!']"
                            hide-details="auto"
                            v-model="charge.quantity"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].quantity
                                ? formErrors.customer_invoice_da_charges[i]
                                    .quantity
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i].quantity
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].quantity
                                : null;
                              calculateAmount(i);
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="1" class="Form-Columns-Dense">
                          <InputField
                            type="number"
                            :label="`Tax Percentage (%) *`"
                            :disabled="!charge.charge"
                            :clearable="false"
                            readonly
                            hide-details="auto"
                            v-model="charge.tax_percentage"
                            :error-messages="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i]
                                .tax_percentage
                                ? formErrors.customer_invoice_da_charges[i]
                                    .tax_percentage
                                : []
                            "
                            @input="
                              formErrors &&
                              formErrors.customer_invoice_da_charges &&
                              formErrors.customer_invoice_da_charges[i] &&
                              formErrors.customer_invoice_da_charges[i]
                                .tax_percentage
                                ? delete formErrors.customer_invoice_da_charges[
                                    i
                                  ].tax_percentage
                                : null;
                              calculateAmount(i);
                            "
                          />
                        </v-col>
                        <v-col cols="12" lg="2" class="Form-Columns-Dense">
                          <InputField
                            hide-details="auto"
                            type="number"
                            :label="`Amount (${$globals.currency})`"
                            :clearable="false"
                            readonly
                            :value="charge.amount_with_tax"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="1"
                          class="Form-Columns-Dense align-self-center"
                        >
                          <v-btn
                            color="secondary"
                            small
                            depressed
                            :disabled="
                              invoiceDetails.customer_invoice_da_charges &&
                              invoiceDetails.customer_invoice_da_charges
                                .length == 1
                            "
                            @click="
                              invoiceDetails.customer_invoice_da_charges.splice(
                                i,
                                1
                              );
                              calculateTotalAmount();
                            "
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-card elevation="0">
              <v-card-text class="pa-2">
                <v-row class="ma-0">
                  <v-col cols="12" lg="12">
                    <v-row justify-lg="center">
                      <v-col cols="12" lg="6" class="Form-Columns-Dense">
                        <span class="text-subtitle-2">
                          <InputField
                            hide-details="auto"
                            type="number"
                            :label="`Total Amount (${$globals.currency})`"
                            readonly
                            :clearable="false"
                            v-model="invoiceDetails.total_amount"
                            :error-messages="formErrors.total_amount"
                            @input="delete formErrors.total_amount"
                          />
                        </span>
                      </v-col>
                      <v-col cols="12" lg="6" class="Form-Columns-Dense">
                        <InputField
                          hide-details="auto"
                          type="number"
                          :label="`Total Gross Amount (${$globals.currency})`"
                          readonly
                          :clearable="false"
                          v-model="invoiceDetails.total_gross_amount"
                          :error-messages="formErrors.total_gross_amount"
                          @input="delete formErrors.total_gross_amount"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </template>

    <template #formActions>
      <v-btn
        color="primary"
        depressed
        :disabled="!isValid || isReadOnly"
        @click="updateMiscellaneousInvoice"
      >
        Update
      </v-btn>
    </template>
  </FormComponent>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import AdminInvoiceChargeButton from "@/components/AgGridComponents/AdminInvoiceChargeButton.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import { TaxTypes } from "@/utils/choices";
export default {
  components: {
    FormComponent,
    AgGridVue,
    InputField,
    MultiSelect,
    AdminInvoiceChargeButton,
    DateField,
    TextareaField,
  },
  data() {
    return {
      backRoute: { path: "/app/admin/invoice/customer/list" },
      defaultColDef: {
        resizable: true,
      },
      formErrors: {},
      invoiceDetails: {
        customer_invoice_da_charges: [],
      },
      keyUpdate: 0,
      isInvoiceFormValid: true,
      chargesValid: true,

      allChargeList: [],
      allCountryList: [],
      allCustomerList: [],
      allBranchList: [],
      taxTypeList: TaxTypes,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isValid() {
      return this.chargesValid && this.isInvoiceFormValid;
    },
    isReadOnly() {
      return this.invoiceDetails && this.invoiceDetails.status == "Accepted";
    },
  },
  methods: {
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      return this.$api.branch
        .getBranchList(params)
        .then((res) => {
          this.allBranchList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCountryList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allCountryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList(params = {}) {
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        limited: true,
      };
      return this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    selectedCharge(val, chargeIndex) {
      if (val) {
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].charge =
          val.id;
        this.invoiceDetails.customer_invoice_da_charges[
          chargeIndex
        ].charge_title = val.charge_title;
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].gl_no =
          val.gl_no;
        this.invoiceDetails.customer_invoice_da_charges[
          chargeIndex
        ].description = val.description;
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].rate =
          val.rate;
        this.invoiceDetails.customer_invoice_da_charges[
          chargeIndex
        ].da_charge_type = "Revenue";
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].tax_type =
          val.tax_type;
        this.invoiceDetails.customer_invoice_da_charges[
          chargeIndex
        ].tax_percentage = val.tax_percentage;

        const rate =
          this.invoiceDetails.customer_invoice_da_charges[chargeIndex].rate;
        const tax =
          this.invoiceDetails.customer_invoice_da_charges[chargeIndex]
            .tax_percentage;
        const quantity =
          this.invoiceDetails.customer_invoice_da_charges[chargeIndex].quantity;
        if (rate && quantity) {
          const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
          if (totalGrossRate && tax) {
            const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);
            this.invoiceDetails.customer_invoice_da_charges[
              chargeIndex
            ].tax_amount = totalTaxAmount.toFixed(4);
            this.invoiceDetails.customer_invoice_da_charges[
              chargeIndex
            ].amount_with_tax = (totalGrossRate + totalTaxAmount).toFixed(4);
          }
          this.keyUpdate++;
        }
      } else if (
        this.invoiceDetails &&
        this.invoiceDetails.customer_invoice_da_charges
      ) {
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex] = {};
      }
      this.keyUpdate++;
      this.calculateTotalAmount();
    },
    calculateAmount(chargeIndex) {
      const rate =
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].rate;
      const tax =
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex]
          .tax_percentage;
      const quantity =
        this.invoiceDetails.customer_invoice_da_charges[chargeIndex].quantity;
      if (rate && quantity) {
        const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
        if (totalGrossRate && tax) {
          const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);
          this.invoiceDetails.customer_invoice_da_charges[
            chargeIndex
          ].tax_amount = totalTaxAmount.toFixed(4);
          this.invoiceDetails.customer_invoice_da_charges[
            chargeIndex
          ].amount_with_tax = (totalGrossRate + totalTaxAmount).toFixed(4);
        }
      }
      this.calculateTotalAmount();
    },
    calculateTotalAmount() {
      let totalGrossAmount = 0;
      let totalNetAmount = 0;
      let totalTaxAmount = 0;

      this.invoiceDetails.customer_invoice_da_charges.forEach(
        (invoiceCharge) => {
          const chargeDetail = { ...invoiceCharge };
          const chargeTax = chargeDetail.tax_percentage;
          const chargeRate = parseFloat(chargeDetail.rate);
          const chargeQuantity = chargeDetail.quantity;
          if (chargeRate && chargeQuantity) {
            const totalChargeRate =
              parseFloat(chargeRate) * parseFloat(chargeQuantity);
            totalNetAmount = totalNetAmount + totalChargeRate;
            if (totalChargeRate && chargeTax) {
              const chargeTotalTaxAmount =
                totalChargeRate * (parseFloat(chargeTax) / 100);

              totalGrossAmount =
                totalGrossAmount + totalChargeRate + chargeTotalTaxAmount;
              totalTaxAmount = totalTaxAmount + chargeTotalTaxAmount;
            }
          }
        }
      );

      this.invoiceDetails.total_tax_amount = totalTaxAmount.toFixed(4);
      this.invoiceDetails.total_amount = totalNetAmount.toFixed(4);
      this.invoiceDetails.total_gross_amount = totalGrossAmount.toFixed(4);
    },
    getChargeList(params = {}) {
      params = {
        limit: "all",
        is_active: "true",
        is_revenue: "true",
      };
      this.$api.charge
        .getChargeList(params)
        .then((res) => {
          this.allChargeList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    updateMiscellaneousInvoice(payload = {}) {
      this.$bus.$emit("showLoader", true);
      payload = {
        id: this.$route.params.id,
        data: { ...this.invoiceDetails },
      };
      delete payload.data.delivery_advices;
      delete payload.data.customer_invoice_das;

      this.$api.customerInvoice
        .updateCustomerInvoice(payload)
        .then(async (res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Invoice updated Successfully!",
            color: "success",
          });
          this.$router.push(this.backRoute);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Invoice!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
    getInvoiceObject(id) {
      this.$api.customerInvoice
        .getCustomerInvoiceObject(id)
        .then((res) => {
          this.invoiceDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    if (
      "id" in this.$route.params &&
      typeof parseInt(this.$route.params.id) == typeof 1
    ) {
      this.getCustomerList();
      this.getChargeList();
      this.getBranchList();
      this.getCountryList();
      this.getInvoiceObject(this.$route.params.id);
    }

    this.setBackRoute();
  },
};
</script>

<style>
.Table-Height {
  width: 100%;
  height: 250px;
  overflow-y: auto;
}
</style>
